/*
  REACT
*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
  TYPOGRAPHY
  https://skylab.avalara.com/77horepq1/p/310671-typography/b/76b3c8
*/
s-col {
  padding-bottom: 5px !important;
}

body {
  color: var(--color-gray-darkest);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dl-product-name {
  font-family: apertura, sans-serif;
}

/*
  COLOR PALETTE
  https://skylab.avalara.com/77horepq1/p/34a815-colors/b/54ec6b
  https://skylabsdk.avalara.io/styles/colors
*/

.dl-brand-orange {
  color: var(--color-brand-orange)
}

.dl-brand-blue {
  color: var(--color-brand-blue)
}

.dl-brand-gray {
  color: var(--color-gray-medium)
}

label {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.cx-quick-search {
  top: -55px;
}

/*
  HYPERLINKS
  https://skylab.avalara.com/77horepq1/p/330673-links/b/06e81d
*/

a {
  color: var(--color-blue-dark)
}

a:hover {
  color: var(--color-link-hover)
}

a:active {
  color: var(--color-link-pressed)
}

a:visited {
  color: var(--color-blue-dark)
}

/*
  FLEXBOX
*/

.dl-flex-fill-height {
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
}

.dl-flex-center {
  justify-content: center;
  align-items: center;
}

.dl-scroll {
  overflow-y: auto;
}

.dl-content {
  margin: 0 auto;
  min-width: 100rem;
  padding: 0 1.85rem;
  max-width: 100rem;
}

/*
  ECM GLOBAL CLASSES
*/
.initial-overflow {
  overflow: initial !important;
}

.th-with-filter>table thead>tr>th {
  padding-bottom: 0.13rem !important;
  padding-top: 0.13rem !important;
}

#zip-upload-table>table thead>tr>th {
  padding-bottom: 0.13rem !important;
  padding-top: 0.13rem !important;
}

.max-content-width {
  width: max-content;
}

.tab-width-validation {
  width: 100% !important;
}

.validation-button-assign {
  border-bottom: solid 1px #C4C4C4 !important;
  margin-bottom: 3px !important;
}

.button-template-field {
  align-items: center;
  background-color: #ddd !important;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-flex;
  height: 1.4rem;
  font-weight: inherit;
}

#zdWidgetHeader {
    height: 165px;
}

.radio-button-fix {
  line-height: 1.4 !important;
}

@media only screen and (max-width: 1600px) {
  .dl-content {
    margin: 0;
    min-width: 0;
    padding: 0 1.85rem;
    max-width: 100rem;
  }
}

@media only screen and (max-width: 1259px) {
  .dl-content {
    margin: 0;
    min-width: 0;
    padding: 0 1.5rem;
    max-width: 100rem;
  }
}

@media only screen and (max-width: 839px) {
  .dl-content {
    margin: 0;
    min-width: 0;
    padding: 0 0.75rem;
    max-width: 100rem;
  }
}

.disable-sinput-extended  input,   .disable-sinput-extended  button {
  pointer-events: none;
  background-color: var(--color-gray-lightest);
  border-color: var(--color-gray-lighter);
  color: var(--color-gray-light);
  cursor: not-allowed;
}

.nested-grid th
{
  color: var(--color-gray-darkest);
  font-weight: var(--font-weight-semibold);
  text-align: left;
  white-space: nowrap;
  vertical-align:middle;
}

.nested-grid th,
.nested-grid td {
  width: 136px;
}

