.company-logo {
    width: -webkit-fill-available; 
    width: 100%;
    height: auto; 
    object-fit: contain;
}
.retail-nav-bar {
    flex-direction: row-reverse;
}
.location-selector {
    min-width: 150px;
}