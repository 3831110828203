.before-provisioning-background {
    background-image: url("https://assets.avalara.com/cup/static/before_provisioning.svg");
    background-size: contain;
    background-repeat: no-repeat;
    align-items: center;
}
.upgrade-box {
    position: relative;
}

.heading {
    line-height: 1.5rem!important;
}

.hanging-indent {
    padding-left: 15px;
    text-indent: -8px;
}


.dialog-style {
    max-height: 100% !important;
    width: 690px;
}

#provision-box {
    border-color: var(--color-green-light);
    background-color: var(--color-green-lightest);
}

#ecm-product {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 60%;
}

#promo-dialog div[ref=content-container] {
    padding: 0px!important;
}

#promo-body {
    position: relative;
    height: 152px!important;
}

#promo-text {
    padding-left: 300px;
    padding-top: 15px;
    padding-right: 15px;
}

#support-link:focus{
    outline: none;
    box-shadow: none;
}

#promo-image {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
}
