.dl-400-background {
    background-image: url("images/400.svg");
    background-size: cover;
    align-items: center;
}
.dl-500-background {
    background-image: url("images/500.svg");
    background-size: cover;
    align-items: center;
}
.dl-noaccess-background {
    background-image: url("images/BackgroundNoAccess.svg");
    background-size: cover;
    align-items: center;
}
.display-inline-block {
    display: inline-block;
}
.float-right {
    float: right;
}
.header-text {
    line-height: 3.20rem !important;
}
.box-css{
    margin-top: 9.03rem!important;
}