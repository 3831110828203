.gray-darker {
    color: #444;
}
.gray-dark {
    color: #6f6f6f;
}

.workspace-letter-spacing {
    letter-spacing: 0.1rem;
}
