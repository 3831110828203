.boxStyle {
    width: 300px;
    float: right;
}

.results {
    overflow: hidden;
    z-index: 2;
    position: absolute;
    width: 300px;
}

.linkStyle {
    right: 20px;
}

.cn-highlight {
    color: var(--color-blue-medium);
    margin-right: 5px;
}

.link-item {
   text-decoration: none;
   color: var(--color-gray-darkest); 
}

.link-item:hover{
    text-decoration: none;
    color: var(--color-gray-darkest); 
}

.link-item:focus {
    outline: none !important;
    box-shadow: none !important;
}

.link-item:visited {
    color: inherit !important;
  }

.selected:hover{
    background: var(--color-gray-lightest);
}
