.customerBoxStyle {
    width: 100% !important;
    overflow: hidden !important;
    z-index: 2;
    position: absolute;
}

.table-style {
    overflow-x: auto;
    white-space: nowrap;
}
.theadStyle {
    background: var(--color-gray-lightest) !important;
}

.hr-width{
    width: 100% !important;
}
