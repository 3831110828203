#no-data-message {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    margin-top: 39px;
    letter-spacing: 0px;
    z-index: 1;
}

#helper-message {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    z-index: 1;
}

#no-data-landing-header {
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
    letter-spacing: 0px;
    z-index: 1;
}

#no-certificates-found,
#filter-message {
    left: 10px;
}
