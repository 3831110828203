.description-style {
    float: right;
    display: block;
    width: 60%;
    font-family: "Source Sans Pro";
    font-size: 14pt;
    margin-right: 10px;
    margin-left: 10px;
}

.image-style {
    width: 241px;
    height: 185px;
    right: 5px;
}

.dialog-style {
    max-height: 100% !important;
    width: 690px;
}
