.company-logo-box {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
}

.company-logo-label {
    font-size: 0.75rem;
}

.accountExpirationTextWidth {
    width: 10%;
}

.top-negative-10 {
    top: -10px;
}

.top-negative-15 {
    top: -15px;
}

.top-negative-20 {
    top: -20px;
}

.top-5 {
    top: 5px;
}

.shrink-column {
    flex-basis: 3.5% !important;
    max-width: 3.5% !important;
}

.blue-icon-dark {
    color: var(--color-blue-dark);
}

.color-gray-dark {
    color: var(--color-gray-dark);
}

.small-select {
    width: 75px;
    display: inline-block;
}

#certificateForms-dialog {
    width: 590px;
}

.dialogCollapsed {
    width: 40.625rem !important;
}

.dialogExpanded {
    width: 750px !important;
}

.col-expanded {
    max-width: 500px !important;
}

.matrix-file {
    width: 200px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    cursor: pointer;
}

.matrix-pdf-file {
    cursor: pointer;
}

#matrixTaxID {
    width: 210px;
}

#advanced-text {
    color: var(--color-gray-dark);
}

.disable-anchor-tag {
    pointer-events: none;
}

.collectionDialog {
    width: 1300px !important;
}

.matrix-regions ul {
    height: auto;
    overflow: scroll;
    overscroll-behavior: contain;
}

.custom-line-height {
    line-height: 0.5rem;
}

.no-line-height {
    line-height: 1px;
}

.small-textbox {
    width: 100px;
    height: 35px;
}

.reply-to-help-text {
    color: var(--color-gray-dark);
}

.action-item-row {
    border-left-style: solid;
    border-left-color:  var(--color-gray-medium);
    border-left-width: thin;
    width: 40px;
}
.category-code {
    background-color: var(--color-gray-lightest);
    font-weight: normal;
}

.box-image-empty{
    width:254px;
    height:198px;
}

.dialog-footer-button {
    width:130px;
}
