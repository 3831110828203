.file-container-border {
    border: 1px solid var(--color-gray-lighter);
}

#img-magnifier-container {
    position: relative;
}

#img-magnifier-glass {
    position: absolute;
    border-radius: 50%;
    cursor: none;
    /*Set the size of the magnifier glass:*/
    width: 150px;
    height: 150px;
    box-shadow: 0 0 0 7px rgb(255 255 255 / 85%), 0 0 7px 7px rgb(0 0 0 / 25%),
        inset 0 0 40px 2px rgb(0 0 0 / 25%);
}
