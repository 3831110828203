.cust-info-box-bg{
    background-color: var(--color-blue-lightest);
  }

@media only screen and (min-width: 320px) and (max-width: 383px)
{
  .h1-text {
    font-weight: 400;
    font-size: 24px;
  }
}

.aui-icon-img > img {
    margin-top: 18px;
}

@media only screen and (min-width: 	320px) and (max-width: 	383px)
{
}

@media only screen and (min-width: 	384px) and (max-width: 	599px)
{
}

@media only screen and (min-width: 600px) and (max-width: 	839px)
{    

}

@media only screen and (min-width: 840px) and (max-width: 1259px)
{
}

@media only screen and (min-width: 1260px) 
{
}