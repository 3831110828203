.request-modal-body {
    width: 550px;
}

#requestDialog {
    min-height: calc(100vh - 90px) !important;
}

.specify-error {
    color: var(--color-red-dark);
}

.error-icon {
    font-weight: 400;
    top: 5px;
    padding-right: 0.375rem;
}
