.standard-card {
    width: 11.25rem;
    height: 11.875rem;
    box-shadow: 0px 1px 1px 0px var(--color-gray-lighter);
    margin-left: 1.125rem;
    right: 0.75rem;
    margin-bottom: 1.5rem;
}

.standard-card-first {
    width: 11.25rem;
    height: 11.875rem;
    box-shadow: 0px 1px 1px 0px var(--color-gray-lighter);
    margin-left: 1.5rem;
    right: 0.75rem;
    margin-bottom: 1.5rem;
}

.icon-img {
    left: 50px;
    top: 30px;
}

.text-line {
    top: 20px;
    text-align: center;
    color: black;
    text-decoration: none !important;
}

.standard-card:hover {
    background: var(--color-blue-lightest);
    cursor: pointer;
    text-decoration: none !important;
    border-color: var(--color-blue-light);
    box-shadow: 0px 1px 1px 0px var(--color-blue-light);
}

.standard-card-first:hover {
    background: var(--color-blue-lightest);
    cursor: pointer;
    text-decoration: none !important;
    border-color: var(--color-blue-light);
    box-shadow: 0px 1px 1px 0px var(--color-blue-light);
}

.icon-link {
    font-size: 41pt !important;
    font-weight: lighter;
    left: 50px;
    color: black;
    top: 30px;
    height: 65px;
}
.upload-text {
    font-weight: lighter !important;
    top: 35px;
}

.icon-button {
    font-size: 41pt !important;
    color: black;
    top: 30px;
}

.no-link {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.card-label-link {
    top: 25px;
    text-align: center;
    color: black;
    text-decoration: none !important;
    height: 65px;
}

.ghost:focus {
    outline: none !important;
    box-shadow: none !important;
}

.insights-box {
    width: 403px;
    height: 650px;
}

.insights-fill {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: var(--color-gray-lightest) !important;
}

.link-convert {
    bottom: 58px !important;
}

.info-box {
    max-width: 2000px;
    overflow: none;
    border-color: var(--color-gray-lighter);
}

.disable-box {
    background-color: transparent !important;
    filter: grayscale(1) !important;
}

.search-table {
    margin-left: -40px !important;
    position: relative;
}

.local-link {
    color: var(--color-blue-dark);
    cursor: pointer;
}

.insights-align {
    right: 0px;
    bottom: 0px;
}

.search-fit {
    left: -90px;
    top: -55px;
}

@media only screen and (min-width: 640px) and (max-width: 1400px) {
    .search-fit {
        left: -8px;
    }
}

.make-responsive {
    display: flex;
    flex-wrap: wrap;
}

.entity-link {
    font-size: 41pt !important;
    font-weight: lighter;
    left: 30px;
    color: black;
    top: 30px;
    height: 65px;
}

.resource-link {
    font-size: 41pt !important;
    font-weight: lighter;
    left: 45px;
    color: black;
    top: 30px;
}

.no-lines td {
    border-bottom: 0px !important;
    border-top: 0px !important;
}

.label-weight {
    font-weight: 600;
}
.add-cert {
    left: 35px !important;
}
.box-empty label {
    font-size: 24px;
    color: #000000;
}

.disable-link {
    pointer-events: none;
}

.disable-box {
    background-color: var(--color-gray-lighter);
    filter: grayscale(1);
}

.zoom-tip {
    right: 4px !important;
}