.no-wrap {
    white-space: nowrap;
}

.top-xs {
    top: 2px;
}

s-select.error > div > input {
    width: 100% !important;
}

.dg-data-grid button.link > s-icon {
    top: 2px;
}

.custom-top-icon {
    top: 3px;
}

.dg-data-grid button.link,
.dg-data-grid button.link:visited {
    color: var(--color-blue-dark) !important;
}

.dg-data-grid button.link:hover {
    color: var(--color-link-hover) !important;
}

.dg-data-grid button.link:active {
    color: var(--color-link-pressed) !important;
}

.dialog-width {
    width: 35%;
}

.unlink-dialog-width {
    width: 20%;
}

.unlink-dialog-text {
    display: flex;
    vertical-align: middle;
}

.dialog-body {
    height: 25vh
}
#async-customer-tab-select > div > button {
    display: none;
} 
.region-dialog-width
{
 max-width: 500px !important;  
}  
.remove-dialog-width {
    width: 25%;
}

.region-dialog-body 
{
    height: 25vh !important;  
} 
.region-dialog-body label {
    padding-bottom: 12px;
}

.blue-lighter {
    background-color: var(--color-blue-lighter);
    border-color:  var(--color-blue-lighter);
}