.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    background-color: var(--color-gray-lightest);
    visibility: hidden;
    min-width: 18rem;
    max-width: 25rem;
    border-radius: 6px;
    padding: 5px 5px 5px 10px;
    position: absolute;
    z-index: 1;
    border: var(--color-gray-lighter) solid 1px;
}

.tooltiptext-right {
    right: 100%;
    top: 0px;
}

.tooltiptext-left {
    left: -200%;
    top: 0px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.file-status-info-align {
    vertical-align: middle !important;
}
