s-table-container > table tbody td {
    height: 0;
}

div[slot="filters-panel-content"] {
    z-index: 2;
}

.margin-top-extended {
    margin-top: 22px;
}

.dg-data-grid button.link > s-icon {
    top: 2px;
}
.dg-data-grid button.link,
.dg-data-grid button.link:visited {
    color: var(--color-blue-dark) !important;
}
.dg-data-grid button.link:hover {
    color: var(--color-link-hover) !important;
}
.dg-data-grid button.link:active {
    color: var(--color-link-pressed) !important;
}
button.icon.small > [name="more"] {
    color: var(--color-blue-dark) !important;
}

.activeCert-td {
    white-space: nowrap;
}

.tagText {
    width: max-content;
}

.active-count {
    float: left;
    width: 15%;
}
.alert-container {
    padding: 0;
    margin: 0;
    min-width: 100%;
}

a:hover{
    text-decoration: none;
}